import { Role } from '@/models/Role';

export const RoleRequest = (argRole) => {
  const role = argRole.copy()

  if (!role.id) {
    delete role.id
  }

  role.permissions = role.permissions.map(p => p.id);

  return role
}

export const RoleResponse = (role) => {
  return new Role(role)
}

export const RolesResponse = (roles) => {
  return roles.map((a) => RoleResponse(a))
}