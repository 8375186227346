import Permission from "@/models/Permission";
import Role from '@/models/Role';
import BaseService from './BaseService';
import { RoleRequest } from './mappers/RoleMapper';

class RoleService extends BaseService {
    constructor() {
        super();
    }

    getMyPermissions() {
        return this.get("my/permissions");
    }

    getPermissions() {
        return this.get("permissions")
                   .then(response => response.data.map(p => new Permission(p)));
    }

    getRoles() {
        return this.get("roles").then(response =>response.data.map(r => new Role(r)));
    }

    getRole(id) {
        return this.get(`roles/${id}`).then(
            response => new Role(response.data)
        );
    }

    deleteRole(id) {
        return this.delete(`roles/${id}`);
    }

    createRole(data) {
        return this.post(`roles`, RoleRequest(data)).then(
            response => new Role(response.data)
        );
    }

    updateRole(id, data) {
        return this.patch(`roles/${id}`, RoleRequest(data)).then(
            response => new Role(response.data)
        );
    }
}

export default new RoleService();